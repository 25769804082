

























































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
import { computed } from "@vue/composition-api";
import { IPlanningView } from "@/types/api-orisis/interfaces/Planning/PlanningViews";
import { IPlanningLine } from "@/types/api-orisis/interfaces/Planning/PlanningLine";
import {
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownDivider,
  BDropdownForm,
  BDropdownGroup,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BTabs,
  BCardText,
  BTab,
  BButtonGroup,
  BButton,
} from "bootstrap-vue";
import { ILinePosition } from "@/types/api-orisis/interfaces/Planning/LinePosition";
import { ETypeLine } from "@/types/api-orisis/enums/enums";
import draggable from "vuedraggable";
import PopupCreatePlanningLine from "@/components/planning/planning-settings/popup-planning-settings/PopupCreatePlanningLine.vue";
@Component({
  mounted(){
    console.group("this.planningViewInstance", this.planningViewInstance)

  },
  components: {
    BListGroup,
    BListGroupItem,
    draggable,
    BDropdown,
    BDropdownDivider,
    BDropdownForm,
    BDropdownGroup,
    BDropdownItem,
    BFormCheckbox,
    BFormGroup,
    PopupCreatePlanningLine,
    BTabs,
    BCardText,
    BTab,
    BButtonGroup,
    BButton,
  },

  directives: {
    Ripple,
  },
  methods: {
    ...mapActions([
      "storePlanningView",
      "fetchDetailPlanningView",
      "fetchPlanningLinesCollaborator",
      "fetchPlanningLinesFreeLine",
      "fetchPlanningLinesAffair",
    ]),
  },
  computed: {
    ...mapGetters([
      "isPlanningViewCreating",
      "createdDataPlanningView",
      "workspaceSelected",
      "isLoadingDetail",
      "updatePlanningView"
    ]),
  },
})
export default class PopupCreatePlanningView extends Vue {
  $refs!: {
    formCreatePlanningView: HTMLFormElement;
  };
  //** Computed */

  //** Datas  */
  planningViewInstance: IPlanningView = {
    name: "",
    order: 0,
    isDefault: false,
    workspaceId: "",
    linePositions: [],
  };
  search: string= "";
  get planningLinesCollaborator(): IPlanningLine {
    return this.$store.getters.planningLinesCollaborator;
  }

  //** Created */
  created() {
    this.getStoreDetailPlanningView(this.$route.params.id);
    this.$store.dispatch("fetchPlanningLinesCollaborator");
    this.$store.dispatch("fetchPlanningLinesFreeLine");
    this.$store.dispatch("fetchPlanningLinesAffair");
  }

  //** Methods */
  addTitleToLinePosition(typeLine: ETypeLine) {
    let FreeLineTemplate: ILinePosition = {
      position: 0,
      name: typeLine == 3 ? "Titre" : "Sous-titre",
      typeLine: typeLine,
      planningLineId: "",
      isLocked: true,
    };
    this.planningViewInstance.linePositions.push(FreeLineTemplate);
  }
  getPlanningViewInstance(id?: string): void {
    
    if (id) {
      let planningView = this.$store.getters.planningView as IPlanningView;
      console.log("get with id ");
      let planningViewInstance: IPlanningView = {
        name: planningView.name,
        order: planningView.order,
        isDefault: planningView.isDefault,
        workspaceId: this.$store.getters.workspaceSelected,
        id: id,
        linePositions: planningView.linePositions,
      };
      console.log("return 1 =>", planningViewInstance);
      this.planningViewInstance = planningViewInstance;
    }
  }
  removeItem(index) {
    this.planningViewInstance.linePositions.splice(index, 1);
  }
  updatePlanningViewMethod(){
    this.$store.dispatch("updatePlanningView", this.planningViewInstance);
  }
  onChange(evt) {
    if (evt.added && !this.planningViewInstance.linePositions[evt.added.newIndex].position) {
      let planningLineStock = JSON.parse(JSON.stringify(this.planningViewInstance.linePositions[evt.added.newIndex]))
      this.planningViewInstance.linePositions[evt.added.newIndex] = {
        position: this.planningViewInstance.linePositions.length,
        label: planningLineStock.label,
        typeLine: ETypeLine.Normal,
        color: "",
        planningLineId: planningLineStock.id as string,
        isLocked: true,
        typeLine: planningLineStock.type
      };
    }
  }
  getStoreDetailPlanningView(id: string): void {
    this.$store
      .dispatch("fetchDetailPlanningView", { id: id, that: this })
      .then((res) => {
        this.getPlanningViewInstance(res.id);
    });
  }

}
